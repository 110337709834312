<template>
  <v-form
    ref="sirenSearch"
    valid="siretFormValid"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="siret"
          name="Business Siret"
          label="Siret de l'entreprise"
          dense
          outlined
          :rules="siretValidationRules"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          :loading="loadingSiret"
          outlined
          icon
          color="primary"
          @click="searchSiret"
        >
          <v-icon>
            {{ icons.mdiMagnify }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-checkbox
          v-model="withOpcos"
          label="Inclure les Opcos"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiMagnify,
      },
      withOpcos: true,
      siret: '89118768400024',
      siretFormValid: true,
      loadingSiret: false,
      siretValidationRules: [
        v => !!v || 'Veuillez saisir le numéro SIRET',
        v => (v && v.length !== 13) || 'Le SIRET fait 14 caractères',
      ],
    }
  },
  methods: {
    searchSiret() {
      this.loadingSiret = true
      if (this.$refs.sirenSearch.validate()) {
        this.$http.get(`${process.env.VUE_APP_API_URL}/utils/siret/${this.siret}?withOpco=${this.withOpcos}`)
          .then(res => {
            console.log(res.data)
            this.$store.commit('snackMe', {
              color: 'success',
              text: `Une entreprise et ${res.data.opcoInfos.length + 1} OPCOs trouvés`,
              value: true,
            })
            this.$emit('sireneInfosFetched', res.data)
          })
          .catch(err => console.log(err))
          .finally(
            this.loadingSiret = false,
          )
      }
    },
  },

}
</script>

<style>

</style>
