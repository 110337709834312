<template>
  <v-form
    ref="businessContactCompanyInfos"
    v-model="valid"
    lazy-validation
  >
    <v-card-text class="mt-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <h3>Recherche par SIRET</h3>
          <p>Compléter les informations de l'entreprise via le SIRET</p>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <BusinessContactFormSiretSearch @sireneInfosFetched="(payload) => businessContact = payload"></BusinessContactFormSiretSearch>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-3"></v-divider>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <h3>Informations légales</h3>
          <p>Saisir les informations légales de l'entreprise</p>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="businessContact.name"
                name="Business name"
                label="Nom de l'entreprise"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                id="addressLine1"
                v-model="businessContact.address.line1"
                autocomplete="address-line1"
                outlined
                dense
                label="Adresse"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                id="addressLine2"
                v-model="businessContact.address.line2"
                autocomplete="address-line2"
                outlined
                dense
                label="Adresse seconde ligne"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              sm="12"
            >
              <v-text-field
                id="addressPostcode"
                v-model="businessContact.address.postcode"
                autocomplete="postal-code"
                outlined
                dense
                label="Code postal"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              md="8"
              sm="12"
            >
              <v-text-field
                id="addressCity"
                v-model="businessContact.address.city"
                autocomplete="address-level2"
                outlined
                dense
                label="Ville"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-divider class="my-3"></v-divider>
            </v-col>
            <v-col>
              <v-text-field
                v-model="businessContact.mainActivityLegalUnit"
                name="Company NAF code"
                label="Code NAF"
                dense
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col md="4">
              <v-text-field
                v-model="businessContact.siret"
                name="Company Siret"
                label="Numéro siret"
                dense
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col
              md="2"
            >
              <v-text-field
                v-model="businessContact.businessStarted"
                name="Company creation date"
                label="Création de l'entreprise"
                dense
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-5">
      <v-btn
        :disabled="false"
        color="primary"
      >
        Enregistrer
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import {
  mdiCalendar,
  mdiPhone,
  mdiMail,
  mdiPencil,
} from '@mdi/js'

import BusinessContactFormSiretSearch from './BusinessContactFormSiretSearch.vue'

export default {
  components: {
    BusinessContactFormSiretSearch,
  },
  props: {

  },
  data: () => ({
    icons: {
      mdiCalendar,
      mdiPhone,
      mdiMail,
      mdiPencil,
    },
    businessContact: {
      name: '',
      siret: '89118768400024',
      address: {
        line1: '',
        line2: '',
        city: '',
        postcode: '',
      },
      mainActivityLegalUnit: '',
      mainActivityLegalWord: '',
      employeeNumber: 0,
      businessSize: '',
      businessType: '',
      opcoInfos: {
        apen: '',
        effarrdiff: '',
        idcc: 0,
        intape: '',
        libelle: '',
        obs: '',
        operateurdecompetence: '',
        pctdiff: '',
      },
    },
    valid: true,
  }),
  mounted() {

  },
  methods: {

  },

}
</script>

<style>

</style>
