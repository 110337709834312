<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon

        @click="show = !show"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Nouvelle entreprise
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiBriefcase }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <BusinessContactFormCompanyInfos
      @sireneInfosFetched="(payload) => businessContact = payload"
    ></BusinessContactFormCompanyInfos>
    <BusinessContactFormOpcoInfosVue :opcos="businessContact.opcoInfos"></BusinessContactFormOpcoInfosVue>
    <v-card-subtitle>
      Récupérer par Siret
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-subtitle>
      Informations
    </v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col>
            <v-text-field
              v-model="businessContact.name"
              name="Business name"
              label="Nom de l'entreprise"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card-subtitle>
          Informations légales
        </v-card-subtitle>
        <v-card-text>
          <v-form>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-subtitle>
          Informations OpCo
        </v-card-subtitle>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                md="4"
              >
                <v-text-field
                  v-model="businessContact.opcoInfos.pctdiff"
                  disabled
                  label="PCTDIFF : "
                  name="pctdiff"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col md="2">
                <v-text-field
                  v-model="businessContact.opcoInfos.idcc"
                  disabled
                  label="IDCC :"
                  name="IDCC Number"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col
                md="6"
              >
                <v-text-field
                  v-model="businessContact.opcoInfos.intape"
                  disabled
                  label="INTAPE :"
                  name="INTAPE Code"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col
                md="4"
              >
                <v-text-field
                  v-model="businessContact.opcoInfos.operateurdecompetence"
                  disabled
                  label="Opérateur de compétence :"
                  name="Opco name"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col
                md="8"
              >
                <v-text-field
                  v-model="businessContact.opcoInfos.libelle"
                  disabled
                  label="Convention"
                  name="Opco label"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-subtitle>
          Effectif
        </v-card-subtitle>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col md="6">
                <v-select
                  v-model="businessContact.businessSize"
                  name="Business size"
                  label="Taille de l'entreprise"
                  :items="companySizes"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col
                md="2"
              >
                <v-text-field
                  v-model="businessContact.employeeNumber"
                  name="Number of employees"
                  label="Effectif formé"
                  min="0"
                  type="number"
                  suffix="employés"
                  dense
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-subtitle>
          Gérants
        </v-card-subtitle>
        <v-card-text>
          <v-form>
            <div
              v-for="(manager, index) in businessContact.managers"
              :key="index"
            >
              <div class="d-flex justify-space-between">
                <v-spacer></v-spacer>
                <v-subheader>Gérant {{ index+1 }}</v-subheader>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="index === 0"
                  icon
                  color="error"
                  @click="removeManager(index)"
                >
                  <v-icon>{{ icons.mdiTrashCan }}</v-icon>
                </v-btn>
              </div>
              <v-row>
                <v-col md="4">
                  <v-text-field
                    v-model="manager.firstName"
                    dense
                    outlined
                    name="Manager firstname"
                    label="Prénom du gérant"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="manager.lastName"
                    dense
                    outlined
                    name="Manager lastname"
                    label="Nom du gérant"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-select
                    v-model="manager.managerStatus"
                    dense
                    outlined
                    :items="managerStatus"
                    name="Manager status"
                    label="Status du gérant"
                  ></v-select>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="manager.phone"
                    dense
                    outlined
                    label="Téléphone"
                    prefix="+33"
                  >
                  </v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="manager.email"
                    dense
                    outlined
                    label="E-mail"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-btn
              color="primary"
              block
              @click="addManager"
            >
              Ajouter un gérant
            </v-btn>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            color="primary"
          >
            text
          </v-btn>
          <v-btn
            color="primary"
            @click="saveBusiness"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiBriefcase,
  mdiRefresh,
  mdiTrashCan,
} from '@mdi/js'
import conversion from '@/assets/files/naf-idcc-opco.json'
import BusinessContactFormCompanyInfos from './BusinessContactFormCompanyInfos.vue'
import BusinessContactFormOpcoInfosVue from './BusinessContactFormOpcoInfos.vue'

export default {
  metaInfo: {
    title: 'Ajouter une entreprise',
    titleTemplate: '%s - Jimbo',
  },
  components: {
    BusinessContactFormCompanyInfos,
    BusinessContactFormOpcoInfosVue,
  },
  data: () => ({
    show: true,
    sirenSearchValid: true,
    loadingSiret: false,
    businessContact: {
      name: '',
      siret: '89118768400024',
      address: {
        line1: '',
        line2: '',
        city: '',
        postcode: '',
      },
      managerStatus: '',
      nafCode: '',
      registredTradeChamber: '',
      employeeNumber: 0,
      businessSize: '',
      businessType: '',
      opcoInfos: [{
        idcc: '',
        intape: '',
        libelle: '',
        opeateurdecompetence: '',
        pctdiff: '',
      }],
      managers: [{
        firstName: 'Jean-Pierre',
        lastName: 'Du Cul',
        phone: '0617401988',
        email: 'coucou@lacoucou.fr',
        managerStatus: '',
      }],
    },
    conversion,
    companySizes: [
      'Micro entreprise (1-10 salariés)',
      'Petite entreprise (10-49 salariés)',
      'Entreprise moyenne (49-249 salariés)',
      'Grande entreprise(+ de 250 salariés)',
    ],
    managerStatus: [
      'Président salarié',
      'Gérant TNS (Travailleur non salarié)',
      'Autre',
    ],
    icons: {
      mdiChevronUp,
      mdiChevronDown,
      mdiBriefcase,
      mdiRefresh,
      mdiTrashCan,
    },
  }),
  methods: {
    saveBusiness() {
      this.businessContact.company = this.$store.state.company._id
      this.$http.post(`${process.env.VUE_APP_API_URL}/business-contact/new`, this.businessContact)
        .then(res => {
          console.log(res)
          if (res.status === 200) {
            let text = 'Entreprise crée avec succès'
            if (res.data.managers.length > 0) {
              text += ` avec ${res.data.managers.length} gérants`
            }
            this.$store.commit('snackMe', {
              color: 'success',
              text,
              value: true,
            })
          }
        })
        .catch(err => console.log(err))
        .finally()
    },
    addManager() {
      this.businessContact.managers.push({
        firstName: '',
        lastName: '',
      })
    },
    removeManager(index) {
      this.businessContact.managers.splice(index, 1)
    },
  },

}
</script>

<style>

</style>
