<template>
  <v-form
    ref="businessContactOpco"
    v-model="valid"
    lazy-validation
  >
    <v-card-text class="mt-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <h3>Opérateur de compétence</h3>
          <p>Informations sur les OPCOs</p>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-row>
            <v-col cols="12">
              <v-select
                v-if="opcos.length > 0"
                id="opcoSelect"
                v-model="opcos"
                name="OPCO Selector"
                label="Selection de l'OPCO"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                id="opcoApen"
                v-model="opcos[0].apen"
                name="Opco APEN"
                label="Code APEN de l'Opérateur de compétence"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                id="opcoeffarrdiff"
                v-model="opcos[0].effarrdiff"
                outlined
                dense
                name="Opco effarrdiff"
                label="Code effarrdiff de l'Opérateur de Compétence"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                id="opcoIdcc"
                v-model="opcos[0].idcc"
                outlined
                dense
                label="Code IDCC de l'Opérateur de Compétence"
                name="Opco IDCC"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              sm="12"
            >
              <v-text-field
                id="opcoIntape"
                v-model="opcos[0].intape"
                outlined
                dense
                name="Opco Intape"
                label="Nom INTAPE de l'Opérateur de Compétence"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              md="8"
              sm="12"
            >
              <v-text-field
                id="opcoLibelle"
                v-model="opcos[0].libelle"
                outlined
                dense
                name="Opco label"
                label="Libellé de l'Opérateur de Compétence"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              md="8"
              sm="12"
            >
              <v-text-field
                id="opcoObs"
                v-model="opcos[0].obs"
                outlined
                dense
                name="Opco OBS"
                label="Code OBS de l'Opérateur de Compétence"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              md="8"
              sm="12"
            >
              <v-text-field
                id="opcoObs"
                v-model="opcos[0].operateurdecompetence"
                outlined
                dense
                name="Opco name"
                label="Nom de l'Opérateur de Compétence"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              md="8"
              sm="12"
            >
              <v-text-field
                id="opcoObs"
                v-model="opcos[0].pctdiff"
                outlined
                dense
                name="Opco pctdiff"
                label="Spécialisation de l'Opérateur de Compétence"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import {
  mdiCalendar,
  mdiPhone,
  mdiMail,
  mdiPencil,
} from '@mdi/js'

export default {
  components: {
  },
  props: {
    opcos: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    icons: {
      mdiCalendar,
      mdiPhone,
      mdiMail,
      mdiPencil,
    },
    businessContact: {
      opcoInfos: {
        apen: '',
        effarrdiff: '',
        idcc: 0,
        intape: '',
        libelle: '',
        obs: '',
        operateurdecompetence: '',
        pctdiff: '',
      },
    },
    valid: true,
  }),
  mounted() {

  },
  methods: {

  },

}
</script>

<style>

</style>
